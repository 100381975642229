<template>
  <div>
    <!-- <Loader /> -->
    <!-- <Spinner /> -->
    <section class="sign-in-page">
      <b-container class="bg-white mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-sm-6 align-self-center">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
          <div class="col-sm-6 text-center" :style="' background: url('+bgImageURL+') no-repeat 0 0; background-size: cover;'">
            <div class="sign-in-detail text-white" style="background:rgba(0,0,0,0.6);">
              <div>
                  <img :src="require('../../assets/images/2.png')" class="size img-fluid mb-4" alt="logo">
                  <h1 class="mb-1 text-white font-weight-bolder text-monospace">GIRA</h1> 
                  <h5 class="text-white text-monospace">Colegio Santa Joaquina De Vedruna <br> Puente Alto</h5><small class="text-light">v{{ getVersion }}</small>
                </div>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/core/loader/Loader'
import Spinner from '../../components/core/loader/Spinner'
import { core } from '../../config/pluginInit'
import bgImage from '../../assets/images/home.jpg'
import Swiper from '../../components/core/slider/Swiper'
import SwiperSlide from '../../components/core/slider/SwiperSlide'
import {mapGetters} from 'vuex'

export default {
  name: 'AuthLayout',
  components: {
    Loader,
    Spinner,
    Swiper,
    SwiperSlide
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      options: {
        slidesPerView: 1,
        loop: true
      },
      bgImageURL: bgImage
    }
  },
  computed: {
   /* getVersion(){
      return process.env.PACKAGE_VERSION
    }*/
    ...mapGetters({
      getVersion : "getVersion"
    })
  }
};
</script>
<style>
  .size {
   width: 180px;
  }
  @import url("../../assets/css/custom.css");
</style>
